import React from 'react';

const CustomInputComp = (props: any) => {
    const { id, name, type, label, error, value, handleChange, required, placeholder,disabled ,min} = props

    const handleWheel = (e :any) => {
        if (type == "number"){
        e.target.blur();
        } // Blur the input element to prevent further scrolling
      };

      

    return (
        <div className="form-group7 mb-4">
            {label && <label htmlFor="your-name" className="form-label">{label} {required && <span className="red_star">*</span>}</label>}
            <input
                type={type}
                className={`form-control ${error && 'is-invalid'}`}
                value={value}
                onChange={handleChange}
                id={id}
                name={name}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
                min={min}
                onWheel={handleWheel} 
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    )
}
export default CustomInputComp;
