import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const CustomAlertComp = (props: any) => {
  const { message, type } = props.alert;
  useEffect(() => {
    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'warning':
        toast.warning(message);
        break;
      default:
        toast.info(message);
        break;
    }
  }, [props.alert]);

 

 
  return <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={true}
    closeOnClick={true}
    rtl={false}
    pauseOnFocusLoss={false}
    draggable
    pauseOnHover
    theme="light"
    style={{zIndex: 11113}}
  />;
};

export default CustomAlertComp;
