import React, { useState } from 'react'
import CustomInputComp from '../CustomInputComp'
import { experienceRequiredYear, jobTiming, participationType, salaryType, stipend, workPlaceType, workingDays } from '../../utilities/app-const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import './../../styles/careersCss/jobApplyForm.css'

export const GeneralCaseCompetitionsStep2 = (props: any) => {

    const { error, value, handleChange, startDate, disabled ,enddate ,noofapplication} = props;

    const [particpation, setParticpation] = useState("")

    //console.log('numberOfApplicationsAllowed', props)
    const handleWheel = (e :any) => {
        e.target.blur();
      };
      
    return (
        <div className='profile-Form'>

            <h5 className='mb-4'>Applications Details </h5>

            <label className="form-label">Participation Type</label>
            <div className="row form-group mb-4">
                {participationType.map((participationTypeOption, index) => (
                    <div className='col' style={{ position: "relative" }}>
                        <label key={index} htmlFor={`participationType-${index}`} className={`form-label designation-box
    
                         ${value.participationType == participationTypeOption.value ? 'active' : ''}`}
                            onClick={() => {
                                if (!disabled) {
                                    handleChange('participationType', participationTypeOption.value);
                                    setParticpation(participationTypeOption.value);
                                }
                            }}>
                            <span className="checkbox_title">{participationTypeOption.title}</span>
                            {value.participationType == participationTypeOption.value && (<div className="selected-check">
                                <FontAwesomeIcon icon={faCircleCheck} />
                            </div>)}
                        </label>
                    </div>
                ))}
                 {error.participationType && <div className="text-danger">{error.participationType}</div>} 
            </div>

            {particpation == "2" &&
                (<>
                    <label className="form-label">Participation as a team </label>
                    <div className="salary-input mb-2">
                        <input
                            type="number"
                            placeholder="Min"
                            value={value.min_participation}
                            onChange={(e: any) => { handleChange('min_participation', e.target.value) }}
                            onWheel={handleWheel} 
                        />
                        <input
                            type="number"
                            placeholder="Max"
                            value={value.max_participation}
                            onChange={(e: any) => { handleChange('max_participation', e.target.value) }}
                            onWheel={handleWheel} 
                        />
                    </div>

                    {error.max_participation && <div className='text-danger'>{error.max_participation}</div>}
                    {error.min_participation && <div className='text-danger'>{error.min_participation}</div>}
                </>
                )}

               <CustomInputComp
                type={"date"}
                label="Application Start Date"
                id="startDate"
                name="startDate"
                value={value.startDate}
                required={true}
                error={error.startDate}
                handleChange={(e: any) => { handleChange('startDate', e.target.value) }}
                disabled={disabled}
            />

<CustomInputComp
                type={"date"}
                label="Application End Date"
                id="endDate"
                name="endDate"
                value={value.endDate}
                min={value.startDate}
                required={true}
                error={error.endDate}
                handleChange={(e: any) => { handleChange('endDate', e.target.value) }}
                disabled={enddate}
            />
           

            <CustomInputComp
                type={"number"}
                label="Number of Applications allowed"
                id="numberOfApplicationsAllowed"
                name="numberOfApplicationsAllowed"
                //required={true}
                value={value.numberOfApplicationsAllowed}
                handleChange={(e: any) => { handleChange('numberOfApplicationsAllowed', e.target.value) }}
               // error={error.numberOfApplicationsAllowed}
                disabled={noofapplication}
                placeholder={"Leave blank for unlimited applicants"}
            />
        </div>
    )
}

