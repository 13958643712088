import React from 'react';
import './../styles/rightModal.css';

const RightModalComponent = (props: any) => {
	//name & showName is coming from manage registration page only

	const { title, show, handleModal, children, width , name ,showName } = props;
	return (
		<div className={`right-side-modal ${show ? 'open' : 'close'}`} style={{width: width}}>
			<div className="overlay" onClick={handleModal}></div>
			<div className="modal-content">
				<div className='d-flex'>
					<h3>{title} {showName == true ? <span>({name})</span> : ""}</h3>
					<button type="button" className="close-btn text-dark" onClick={handleModal}>
						&times;
					</button>
				</div>
				<hr></hr>
				{children}
			</div>
		</div>
	);
};

export default RightModalComponent;
