import React, { useEffect, useState } from 'react'
import CustomInputComp from './CustomInputComp';
import ApiHandler from '../model/ApiHandler';
import CustomSelectComp from './CustomSelectComp';
import CustomAlertComp from './CustomAlertComp';
// import "../styles/CreateOrg.css"


export const initialOrg = {
    org: "",
    orgType: "",
    image: "",
}

const CreateOrg = (props: any) => {

    const apiHandler = new ApiHandler();

    const { setShowForm, setOptions } = props;

    const [formData, setFormData] = useState(initialOrg);
    const [orgType, setOrgType] = useState([]);
    const [alert, setAlert] = useState<any>({
        msg: '',
        type: '',
        isActive: false
    })

    const handleChanges = (name: string, value: string) => {
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const getOrganizationList = () => {
        apiHandler.getAllOrganizationList((message: string, data: any) => {
            if (data?.organization_name.length !== 0) {
                const options = data?.organization_name?.map((item: any) => ({
                    value: item.id,
                    label: item.name
                }));
                setOptions(options);
                return;
            }
        }, (message: any) => {
            //console.log('Error fetching organization:', message);
        });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        apiHandler.createOrganization(formData, (message: string) => {
            setAlert({
                message: message,
                type: 'success',
                isActive: true
            });
            getOrganizationList();
            setTimeout(() => {
                setShowForm(false);
            }, 2000);
            
        }, (message: any) => {
           // console.log('Error fetching categories:', message);
            for (const [, value] of Object.entries(message.error)) {
                setAlert({
                    message: `${value}`,
                    type: 'error',
                    isActive: true
                });
                //setShowForm(false)
                //  toast.error(message);
            }
        });
    };





    const getOrgType = () => {
        apiHandler.getOrganizationList((message: string, data: any) => {
            if (data?.organizationTypes.length !== 0) {
                const options = data?.organizationTypes?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
                setOrgType(options);
                return;
            }
        }, (message: any) => {
           // console.log('Error fetching specialization:', message);
        });
    }


    useEffect(() => {
        getOrgType();
    }, []);
    // // getOrganizationList

    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const handleImageChange = (event: any) => {
        const file = event.target.files?.[0];
        if (!file) return;
        handleChanges("image", file);
        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === 'string') {
                setSelectedImage(result);
            }
        };
        reader.readAsDataURL(file);
    };

    return (

        <div>

            {/* Your form components here */}
            <div className="right-side-modal open" style={{ width: "50%" }}> {/* Apply the 'open' class when 'showForm' is true */}
                <div className="overlay"
                //onClick={() => setShowForm(false)}
                ></div>

                <div className="modal-content">
                    <button className="close-btn"
                        onClick={() => setShowForm(false)}
                    >
                        &times;
                    </button>
                    <h5>Create Organisation</h5>
                    <hr />
                    <form >
                        <label>Company Logo *</label>
                        <div className="image-uploader">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                                id="upload-image"
                            />
                            <label htmlFor="upload-image">
                                <div className="upload-box">
                                    {selectedImage ? (
                                        <>
                                            <img src={selectedImage} alt="Uploaded" className="uploaded-image" />
                                        </>
                                    ) : (
                                        <span>Click to upload Icon</span>
                                    )}
                                </div>
                            </label>
                        </div>

                        <CustomInputComp
                            type="org"
                            label="Organisation"
                            id="org"
                            name="org"
                            required={true}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleChanges('org', e.target.value);
                            }}
                        />

                        {orgType &&
                            <CustomSelectComp
                                label="Organisation Type"
                                name={'orgType'}
                                id="orgType"
                                required={true}
                                value={formData.orgType}
                                options={orgType}
                                //error={error.orgType}
                                handleChange={handleChanges}
                            />}

                        <button type="submit" className="btn" onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
            </div>
            <CustomAlertComp alert={alert} />
        </div>
    )
}

export default CreateOrg