interface ApiResponse {
  status: number;
  message: string;
  access_token: string;
}

const TOKEN_KEY = 'authToken';

export const storeToken = async (token: string): Promise<void> => {
  try {
    localStorage.setItem(TOKEN_KEY, token);
  } catch (error) {
    console.error('Error storing token:', error);
  }
};

export const getUserToken = async (): Promise<string | null> => {
  try {
    const token = localStorage.getItem('authToken');
    return token || null; 
  } catch (error) {
    console.error('Error getting user token:', error);
    return null;
  }
};

export const removeToken = async (): Promise<void> => {
  try {
    storeToken('null');
    localStorage.removeItem(TOKEN_KEY);
  } catch (error) {
    console.error('Error removing token:', error);
  }
};

export const storeUser = async (user: any): Promise<void> => {
  try {
    localStorage.setItem('user', JSON.stringify(user));
  } catch (error) {
    console.error('Error storing token:', error);
  }
};

export const getUser = async () => {
  try {
    const user = localStorage.getItem('user');
    return user;
  } catch (error) {
    console.error('Error getting user token:', error);
    return null;
  }
};

export const removeUser = async (): Promise<void> => {
  try {
    localStorage.removeItem('user');
    window.location.href = '/';
  } catch (error) {
    console.error('Error removing user:', error);
  }
};
