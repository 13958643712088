import React, { useState, useEffect } from 'react';
import './../styles/componentCss/customLoader.css'; // Import your CSS file for styling

const CustomLoader = (props: any) => {
  const { isLoading } = props

  return (
    <div className={`loader-container ${isLoading ? 'visible' : 'hidden'}`}>
      <div className="loader"></div>
    </div>
  );
};

export default CustomLoader;
