import React, { useEffect, useState } from 'react';
import './../styles/CustomSelect.css'
import Creatable from 'react-select/creatable';
import CustomInputComp from './CustomInputComp';
import CreateOrg from './CreateOrg';
import ApiHandler from '../model/ApiHandler';

// export const initialOrg = {
//    org: "" ,
//    orgType: ""
// }

function CustomSelectComp(props: any) {

    const apiHandler = new ApiHandler(); 

     const { label, required, options, value, name, handleChange, error, placeHolder,disabled ,setOrganisationList } = props;
   
    
     const [showForm, setShowForm] = useState(false);
    
   
    const handleCreateOption = (inputValue: any) => {
        // Toggle the form visibility
        setShowForm(true);
    };

    

    
   

    return (
        <div className="form-group7 mb-4 custom-select">
            <label htmlFor="your-name" className="form-label">{label} {required && <span className="red_star">*</span>}</label>
            <Creatable
                defaultValue={value}
                value={value}
                options={options}
                className={`${error && 'is-invalid'}`}
                placeholder={placeHolder}
                onChange={(opt, meta) => {
                    if (meta.action === 'create-option') {
                        handleCreateOption('alert')
                    } else {
                        handleChange(name, opt);
                    }
                }}
                isDisabled={disabled}
            />
            {error && <div className="invalid-feedback">{error}</div>}

             {showForm && (  
                <>
                <CreateOrg setShowForm={setShowForm} setOptions={setOrganisationList} organisationList={options} />
                </>
              )}    
        </div>
    )
}

export default CustomSelectComp
