import {
  faDesktop,
  faFileContract,
  faLaptop,
  faMars,
  faMarsStrokeH,
  faMercury,
  faTransgender,
  faVenus,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";

export const AppRoutesConst = Object.freeze({
  // public
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  register: "register",
  learn: "learn",
  CertificationCourses: "/certificationCourses",
  crashCourse: "/crashCourse",
  courseDetail: "/course-details/:courseId",
  courseContent: "/course-details/:courseId/course-content/:activeId",
  mentorship: "mentorship",
  becomeMentor: "/mentorship/become-a-mentor",
  mentorForm: "/mentor/become-mentor",
  findMentor: "/find-a-mentor",
  MentorDetailPage: "/mentor/:id",
  ManageMentor: "/mentor/manage/:id/:mentorId",
  practice: "/practice",
  pricing: "/pricing",
  Billing: "/:id/billing",
  pricingPage: "/pageprice",
  compete: "/compete",
  jobs: "/jobs",
  jobOpportunity: "/opportunity-job",
  jobPostForm: "/form/:id",
  jobPostDetail: "/jobDetails/:id",
  exploreJobs: "/explore-job",
  interships: "/interships",
  GeneralComp: "/General&CaseCompetitions",
  WebinarsWorkshops: "/Webinars&Workshops",
  Quizzes: "/Quizzes",
  jobApplyForm: "/jobApply/:id",
  employers: "/employers",
  quiz: "/online-quizing",
  partners: "/partner",
  clients: "clients",
  aboutUs: "about-us",
  faqs: "faqs",
  terms: "terms-and-conditions",
  privacy: "privacy-policy",
  career: "career",
  jobDetail: "job-detail/:id",
  JobCreateForm: "career/create-jobs",
  CareerApplyForm: "career/:id/apply",
  BookMentor: "mentor/book-mentor/:mentorId/:sessionId",
  MentorBooked: "mentor/booked/:mentorId/:sessionId",
  UserProfile: "/my-profile/:id",
  OppMainDash: "/dashboard/:oppId/OppDashboard/:id",
  ChoosePlan: "/pricing/choose-plan/:id",
  PricingPlans: "/pricing/pricing-plans",
  Certificate: "/certificate/:id",
} as const);

export const AppStorage = Object.freeze({
  me: "33-c-user",
  token: "33-x-token",
} as const);

export const gender = [
  { icon: faMars, title: "Male", value: "1" },
  { icon: faVenus, title: "Female", value: "2" },
  { icon: faTransgender, title: "Transgender", value: "3" },
  { icon: faVenusMars, title: "Intersex", value: "4" },
  { icon: faMercury, title: "Non-binary", value: "5" },
  { icon: faMarsStrokeH, title: "Others", value: "6" },
];

export const offerOptions = [
  { title: "Offer Accept", value: "1" },
  { title: "Offer Decline", value: "2" },
];

export const designation = [
  { title: "College Students", value: "1" },
  { title: "Professional", value: "2" },
  { title: "Fresher", value: "4" },
  // { title: 'Others', value: '5' },
];

// export const passout = [
//     { title: '2020', value: '2020' },
//     { title: '2021', value: '2021' },
//     { title: '2022', value: '2022' },
//     { title: '2020', value: '2023' },
// ]
export const abled = [
  { title: "yes", value: 1 },
  { title: "no", value: 0 },
];

export const organizationOptions = [
  { value: "company1", label: "Company 1" },
  { value: "company2", label: "Company 2" },
  // Add more options as needed
];

export const industryOptions = [
  { value: "", label: "Select an industry" },
  { value: "it", label: "IT" },
  { value: "finance", label: "Finance" },
  // Add more options as needed
];

export const initialMentorValue = {
  firstName: "",
  lastName: "",
  profileLink: "",
  gender: "1",
  mobileCode: "+91",
  phoneNumber: "",
  organization: "",
  industry: null,
  role: "",
  experience: "",
  heading: "",
  bio: "",
  youtubeLink: "",
  linkedinLink: "",
  facebookLink: "",
  instagramLink: "",
  domain: null,
  topics: null,
  skills: null,
  languages: null,
  current_role: "",
  education: null,
  image_url: null,
  image_id: null,
};

export const initialFilterValues = {
  all: "",
  language: "",
  topics: "",
  skill: "",
  organization: "",
  experience: "",
};

export const initialEducationValues = {
  qualification_id: "",
  degree: "",
  from_year: null,
  to_year: null,
  percentage: null,
  cgpa: null,
  specialization: "",
  university: "",
  organization_id: null,
  country: "",
  state: "",
  city: "",
  institute: "",
};

export const initialEducationErrors = {
  qualification_id: "",
  degree: "",
  from_year: null,
  to_year: null,
  percentage: null,
  cgpa: null,
  specialization: "",
  university: "",
  organization_id: null,
  country: "",
  state: "",
  city: "",
  institute: "",
};

export const initialWorkValues = {
  designation: "",
  sector: "",
  employment_type: "",
  organisation: null,
  working_on: "false",
  from_year: null,
  to_year: null,
  country: "",
  state: "",
  city: "",
};

export const initialWorkErrors = {
  designation: "",
  sector: "",
  employment_type: "",
  organisation: null,
  working_on: "",
  from_year: null,
  to_year: null,
  country: "",
  state: "",
  city: "",
};
export const initialJobValue = {
  firstName: "",
  lastName: "",
  profileLink: "",
  gender: "",
  mobileCode: "+91",
  phoneNumber: "123456789",
  organization: null,
  industry: null,
  role: "",
  experience: "",
  heading: "",
  bio: "",
  youtubeLink: "",
  linkedinLink: "",
  facebookLink: "",
  instagramLink: "",
  domain: null,
  topics: null,
  skills: null,
  languages: null,
};

export const daysName = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const experienceYear = [
  { title: "1 year", value: "1-year" },
  { title: "2 year", value: "2-year" },
  { title: "3 year", value: "3-year" },
  { title: "4 year", value: "4-year" },
  { title: "5 year", value: "5-year" },
  { title: "6 year", value: "6-year" },
  { title: "7 year", value: "7-year" },
  { title: "8 year", value: "8-year" },
  { title: "9 year", value: "9-year" },
  { title: "10 year", value: "10-year" },
  { title: ">10 year", value: ">10-year" },
];

export const experienceYears = [
  { title: "1 year", value: "1" },
  { title: "2 year", value: "2" },
  { title: "3 year", value: "3" },
  { title: "4 year", value: "4" },
  { title: "5 year", value: "5" },
  { title: "6 to 10 year", value: "6" },
  { title: "10+ year", value: "7" },
];

//job post form

export const workingDays = [
  { title: "4 days", value: "4" },
  { title: "5 days", value: "5" },
  { title: "6 days", value: "6" },
  { title: "Alternate Saturdays Off", value: "8" },
];

export const jobTiming = [
  { title: "Full Time", value: "full_time" },
  { title: "Part Time", value: "part_time" },
  { title: "Contractual/Temporary", value: "contractual" },
  { title: "Volunteer", value: "volunteer" },
];

export const workPlaceType = [
  { title: "In Office", value: "in_office", icon: faLaptop },
  { title: "Work From Home", value: "wfh", icon: faDesktop },
  // { title: 'On Field', value: 'on_field',icon: faFileContract },
  { title: "Hybrid", value: "hybrid", icon: faFileContract },
];

export const experienceRequiredYear = [
  { title: "Fresher: 0 to 1", value: "Fresher:0to1" },
  { title: "Junior: 1 to 3", value: "Junior:1to3" },
  { title: "Mid-level: 4 to 6", value: "Mid-level:4to6" },
  { title: "Senior: 7 to 10", value: "Senior:7to10" },
  { title: "Executive: 10+", value: "Executive:10+" },
];

export const salaryType = [
  { title: "Range", value: "1" },
  { title: "Fixed", value: "0" },
];

export const stipend = [
  { title: "Paid", value: "1" },
  { title: "Unpaid", value: "0" },
];

export const opportunityType = [
  { title: "Job", value: "job" },
  { title: "Intership", value: "intership" },
  {
    title: "General & Case Competitions + Incentive",
    value: "general&CaseCompetitions",
  },
  { title: "Quizzes", value: "quizzes" },
  { title: "Webinars & Workshops", value: "webinars&workshops" },
];

export const participationType = [
  { title: "Individual", value: "1" },
  { title: "Participation as a team", value: "2" },
];

export const modeOfEvent = [
  { title: "Online Mode", value: "1", icon: faLaptop },
  { title: "Offline Mode", value: "0", icon: faDesktop },
];
export const employmentType = [
  { title: "Part_time", value: "1" },
  { title: "Full_time", value: "2" },
  { title: "Freelancer", value: "3" },
  { title: "Internship", value: "4" },
];

export const price = [
  { title: "Cash", value: "1" },
  // { title: 'Kind', value: '0'},
];
