import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getUserToken } from "../components/AppStorageKey";
import ApiHandler from "../model/ApiHandler";
import CustomAlertComp from "../components/CustomAlertComp";

export const Footer = () => {
    const apiHandler = new ApiHandler();
    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState<any>({
        msg: '',
        type: '',
        isActive: false
    })
    

    const handleChange = (e :any) => {
        setEmail(e.target.value);
    };

    
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const token = await getUserToken(); 
        apiHandler.postsubscribe(email, token, (message: string, data: any) => {
            setAlert({
                message: message,
                type: 'success',
                isActive: true
            });
            setEmail('')
        }, 
        (message: any) => {
           // console.log('Error fetching categories:', message);
                setAlert({
                    message: message, 
                    type: 'error',
                    isActive: true
                });
            
        } 
    );
}; 


    return (
      <div>
        <div className="news">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-5">
                <div className="con">
                  <div className="conn">
                    <h3>
                      Stay <span>Updated</span>
                    </h3>
                    <p>
                    Stay in the loop with timely updates
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <div className="con2">
                  <div className="inp">
                    <input
                      type="text"
                      className="inp1"
                      placeholder="Enter Your Email Address"
                      value={email}
                      onChange={handleChange}
                    />
                    <button onClick={(e: any) => handleSubmit(e)}>
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className="container">
            <div className="row">
            

              <div className="col-12 col-lg-6 panel">
                <div className="inner1 h-100" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="view1">
                    <div className="logo">
                      <img
                        src={require("./../assets/images/logo.png")}
                        className="img-fluid" style={{maxWidth : "75%" }}
                      />
                    </div>
                    <h3>Built with ❤️ in India for Indians</h3>
                  </div>

                </div>
              </div>
              {/* <div className="col-12 col-lg-6 panel links">
                <div className="bg-img">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      
                      <ul className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingTwo"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwo"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo"
                            >
                              Services
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo"
                          >
                            <div className="accordion-body">
                              <Link to="/learn">
                                <li>
                                  <img
                                    src={require("./../assets/images/pap.png")}
                                    className="img-fluid me-2 pap"
                                  />
                                  <a href="learn.php">Learn</a>
                                </li>{" "}
                              </Link>

                              <li>
                                <Link to="/jobs">
                                  <img
                                    src={require("./../assets/images/pap.png")}
                                    className="img-fluid me-2 pap"
                                  />
                                  <a href="practice.php">Jobs</a>
                                </Link>
                              </li>
                              <Link to="/mentorship">
                                <li>
                                  <img
                                    src={require("./../assets/images/pap.png")}
                                    className="img-fluid me-2 pap"
                                  />
                                  <a href="mentorship.php">Mentorship</a>
                                </li>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </ul>
                      <ul className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingEight"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseEight"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseEight"
                            >
                              Findings
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingEight"
                          >
                            <div className="accordion-body">
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="jobs.php">Jobs</a>
                              </li>
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="employers.php">Employers</a>
                              </li>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </div>
                    <div className="col-12 col-lg-3">
                      <ul className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingThree"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseThree"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseThree"
                            >
                              Our Values
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingThree"
                          >
                            <div className="accordion-body">
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="about-us">About Us</a>
                              </li>
                              
                            </div>
                          </div>
                        </div>
                      </ul>
                      <ul className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingFour"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseFour"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseFour"
                            >
                              Apply
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingFour"
                          >
                            <div className="accordion-body">
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="partner.php">Partner</a>
                              </li>
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="careers.php">Careers</a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <h6></h6>
                      </ul>
                    </div>

                    <div className="col-12 col-lg-4">
                      <ul className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingThree123"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseThree125"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseThree125"
                            >
                              Competitions
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseThree125"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingThree123"
                          >
                            <div className="accordion-body">
                              
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="Quizzes">Quizzes</a>
                              </li>
                            </div>
                          </div>
                        </div>
                      </ul>
                      <ul className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingFour2323"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseFour6253"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseFour6253"
                            >
                              Information
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseFour6253"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingFour2323"
                          >
                            <div className="accordion-body">
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="faqs">FAQ's</a>
                              </li>
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="terms-and-conditions">Terms & Conditions</a>
                              </li>
                              <li>
                                <img
                                  src={require("./../assets/images/pap.png")}
                                  className="img-fluid me-2 pap"
                                />
                                <a href="privacy-policy">Privacy Policy</a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <h6></h6>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-12 col-lg-6 panel">
                <div className="inner1 h-100" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="view1">
                    <h2>Stay Connected</h2>
                    <ul>
                      <li>
                        <img
                          src={require("./../assets/images/ms.png")}
                          className="img-fluid me-2 siri"
                        />
                        <a href="mailto:support@33careers.com">support@33careers.com</a>
                      </li>
                      <li>
                        <img
                          src={require("./../assets/images/ph.png")}
                          className="img-fluid me-2 siri"
                        />
                        <a href="javascript:;" style={{pointerEvents: "none", cursor: "default" }}>+91-0001111222</a>
                      </li>
                      <li className="timing">(Mon to Fri, 10 AM to 6 PM)</li>
                    </ul>
                  </div>
                  <div className="view2">
                  Strategia Advisor Private Limited, 147 Mahatma Gandhi Road, Fort, Mumbai 400001,
                  (INDIA)
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="copi text-center">
            Copyright © 2023 33careers - All rights reserved.
          </div>
        </footer>

        <nav className="nav_bottom">
          <ul className="navbar-nav mobile-tabs mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/learn">
                <img
                  src={require("./../assets/images/learn.png")}
                  className="imggg"
                  alt=""
                />{" "}
                Learn
              </Link>
            </li>
            {/* <li className="nav-item">
                                <Link className="nav-link" to="/practice"><img src={require("./../assets/images/practice.png")} className="imggg" alt="" />Practice</Link>
                            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/mentorship">
                <img
                  src={require("./../assets/images/mentorship.png")}
                  className="imggg"
                  alt=""
                />{" "}
                Mentorship
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/compete">
                <img
                  src={require("./../assets/images/complete.png")}
                  className="imggg"
                  alt=""
                />{" "}
                Compete
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/jobs"><img src={require("./../assets/images/jobs.png")} className="imggg" alt="" /> Jobs</Link>
            </li>

            {/* <li className="nav-item">
                                <a id="toggleBTN" className="navbar-brand" onClick={() => setShowHeader(!showHeader)}><FontAwesomeIcon icon={faBars} /></a>
                            </li> */}
          </ul>
        </nav>
        <CustomAlertComp alert={alert} />
      </div>
    );
} 